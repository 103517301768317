import React from "react";

const FeedIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5442_4)">
    <path d="M1.40625 12C2.18179 12 2.83594 11.3693 2.83594 10.5938C2.83594 9.81821 2.18179 9.16406 1.40625 9.16406C0.630707 9.16406 0 9.81821 0 10.5938C0 11.3693 0.630707 12 1.40625 12Z" fill="#9A9A9A"/>
    <path d="M0.351562 7.03125H1.05469C3.19043 7.03125 4.96875 8.80948 4.96875 10.9453V11.6484C4.96875 11.8426 5.12613 12 5.32031 12H6.02344C6.21762 12 6.375 11.8426 6.375 11.6484V10.9453C6.375 8.03284 3.96716 5.625 1.05469 5.625H0.351562C0.157379 5.625 0 5.78238 0 5.97656V6.67969C0 6.87387 0.157379 7.03125 0.351562 7.03125Z" fill="#9A9A9A"/>
    <path d="M0.351562 4.21875H1.05469C4.7438 4.21875 7.78125 7.2562 7.78125 10.9453V11.6484C7.78125 11.8426 7.93863 12 8.13281 12H8.83594C9.03012 12 9.1875 11.8426 9.1875 11.6484V10.9453C9.1875 6.47955 5.52045 2.8125 1.05469 2.8125H0.351562C0.157379 2.8125 0 2.96988 0 3.16406V3.86719C0 4.06137 0.157379 4.21875 0.351562 4.21875Z" fill="#9A9A9A"/>
    <path d="M1.05469 0H0.351562C0.157379 0 0 0.157379 0 0.351562V1.05469C0 1.24887 0.157379 1.40625 0.351562 1.40625H1.05469C6.29709 1.40625 10.5938 5.70291 10.5938 10.9453V11.6484C10.5938 11.8426 10.7511 12 10.9453 12H11.6484C11.8426 12 12 11.8426 12 11.6484V10.9453C12 4.92627 7.07373 0 1.05469 0Z" fill="#9A9A9A"/>
    </g>
    <defs>
    <clipPath id="clip0_5442_4">
    <rect width="12" height="12" fill="white"/>
    </clipPath>
    </defs>
    </svg>

}
export default FeedIcon