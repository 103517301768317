import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import ContentWrapper from "../global/components/ContentWrapper";
import Header from "../global/components/Header";
import AdminHeader from "../global/components/AdminHeader";
import Sidebar from "../global/components/Sidebar";
import Page404 from "../global/pages/Page404";

import HomePage from "../pages/Home/HomePage";
import Login from "../pages/Login/LoginPage";
import UserDetails from "../pages/UserDetails";
import UserPage from "../pages/Users/UsersPage";
import Feeds from "../pages/Feeds";
import Admin from "../pages/Admin";
const PublicLayout = ({ children }) => {
  const [navHeight, setNavHeight] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const topNav = document.getElementById("top-navbar");
      if (topNav) {
        let height = topNav.offsetHeight;
        setNavHeight(height);
      }
    }, 100);
  }, []);
  const location = useLocation();

  // Determine if the current route is public or not
  const isPublicRoute = location.pathname.startsWith("/admin");
  return (
    <div
      className="p-0 bg-[#f5f5f5]"
      style={{ "--nav-height": `${navHeight}px` }}
    >
      {isPublicRoute ? <AdminHeader /> : <Header />}
      <div id="main-container" style={{ marginTop: "var(--nav-height)" }}>
        <div className="responsive-padding">
          <ContentWrapper>{children}</ContentWrapper>
        </div>
      </div>
    </div>
  );
};

const PublicUserRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  return <PublicLayout>{<Outlet />}</PublicLayout>;
};
const AuthUserRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  return (
    <PublicLayout>
      {token ? <Outlet /> : <Navigate to={"/admin/login"} />}
    </PublicLayout>
  );
};

const AppRouter = () => {
  const token = localStorage.getItem("token");
  // if (!token) {
  // 	navigate('/login')
  // }

  return (
    <Routes>
      <Route path="/" element={<PublicUserRoute />}>
        <Route path="/" element={<Feeds />} />
      </Route>
      <Route path="/admin/login" element={<Login />} />
      <Route path="/admin" element={<AuthUserRoute />}>
        <Route path="/admin" element={<Admin />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
