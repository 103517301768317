import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  createProvisionLab,
  stopEC2,
  terminateEC2,
} from "../../endpoints/aws.services";
import BVCard from "../../global/components/BVCard";
import api from "../../api";
import { getAxiosConfig } from "../../endpoints/axios.config";
import axios from "axios";
import NewsFeed from "./NewsFeed";
import NewsSource from "./NewsSource";

const Feeds = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [photos, setPhotos] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [oldfeeds, setOldFeeds] = useState([]);
  const [checked, setChecked] = useState([]);
  const allowMultiple = useRef();
  const [tab, setTab] = useState("feed");

  useEffect(() => {
    if (feeds.length === 10 && Object.keys(photos).length === 10) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [feeds, photos]);

  const handleCheck = (itemId) => {
    const checkedWithoutClickedOne = checked.filter(
      (check) => check !== itemId
    );
    if (checked.includes(itemId)) {
      setChecked(checkedWithoutClickedOne);
    } else {
      setChecked((prev) => [...prev, itemId]);
    }
  };

  return (
    <BVCard>
      <div className="p-0.5 sm:p-1">
        {/* <div className="flex flex-col gap-2 p-10 border rounded-md">
          <h1 className="font-semibold text-lg mb-4">Rss Feeds</h1>
          <select onChange={changeOldFeeds} className="border-gray-600 border">
            <option value="">Select Last Updated Date</option>
            {oldfeeds.map((feed) => {
              return <option>{feed.lastBuildDate}</option>;
            })}
          </select>
          <div className="w-full flex mt-4 items-center">
            <input
              onClick={(e) =>
                e.target.checked
                  ? setChecked(feeds.map((feed) => feed.itemId))
                  : setChecked([])
              }
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 mr-3"
            />
            Check All
          </div>
          {checked.length > 0 && (
            <button
              ref={allowMultiple}
              onClick={() => handleAllowMultipleFeed()}
              class="bg-green-600 text-sm px-3 py-2.5 w-56 h-full font-semibold text-white rounded-sm"
            >
              Allow {checked.length} feeds
            </button>
          )}
          {!loading ? (
            <>
              {feeds.map((feed) => {
                return (
                  <div className="w-full flex py-5 items-start">
                    <div className="w-6 mt-2">
                      <input
                        type="checkbox"
                        checked={checked.includes(feed.itemId)}
                        onChange={(e) => handleCheck(feed.itemId)}
                        value=""
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                    </div>
                    <div
                      className={`w-64 h-32 mt-2 bg-gray-200 ${
                        !photos[feed.itemId] && "animate-pulse"
                      }`}
                    >
                      <a href={feed.link}>
                        <img
                          src={photos[feed.itemId]}
                          alt={feed.title}
                          className="w-full h-full"
                        />
                      </a>
                    </div>
                    <div className="pl-8 w-10/12 flex flex-col text-gray-500 ">
                      <div className="w-auto min-h-9 text-lg pb-1">
                        <a
                          href={feed.link}
                          dangerouslySetInnerHTML={{ __html: feed.title }}
                        />
                      </div>
                      <div className="w-auto h-6 text-sm font-semibold">
                        {feed.creator.substring(9, feed.creator.length - 3)} -{" "}
                        {feed.pubDate.substring(5, 16)}
                      </div>
                      <div
                        className="mt-4 w-11/12 h-auto"
                        dangerouslySetInnerHTML={{
                          __html: feed.description.substring(0, 300) + "...",
                        }}
                      />
                      <div className="w-full flex buttons">
                        <div className="mt-4 w-28 h-10">
                          <button
                            class="bg-green-600 text-sm px-3 py-1.5 w-full h-full font-semibold text-white rounded-sm"
                            onClick={() => handleAllowSingleFeed(feed.itemId)}
                          >
                            Allow
                          </button>
                        </div>
                        <div className="mt-4 ml-2 w-28 h-10">
                          <button
                            onClick={() => handleDelete(feed.itemId)}
                            class="bg-red-500 text-sm px-3 py-1.5 w-full h-full font-semibold text-white rounded-sm"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {[1, 2, 3, 4, 5].map((i) => {
                return (
                  <div className="w-full flex py-4">
                    <div className="w-64 bg-gray-200 animate-pulse h-32"></div>
                    <div className="pl-8 w-full flex flex-col">
                      <div className="w-1/3 h-6 bg-gray-200 animate-pulse "></div>
                      <div className="mt-4 w-3/4 h-4 bg-gray-200 animate-pulse "></div>
                      <div className="mt-4 w-11/12 h-4 bg-gray-200 animate-pulse "></div>
                      <div className="w-full flex buttons">
                        <div className="mt-4 w-28 bg-gray-200 animate-pulse h-10"></div>
                        <div className="mt-4 ml-2 w-28 bg-gray-200 animate-pulse h-10"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div> */}
        {/* <div class="flex items-center bg-white flex-1 rounded-lg shadow-nShadow h-14 justify-around w-10/12">
          <button
            class="flex flex-col justify-center items-center h-full relative"
            onClick={() => setTab("feed")}
          >
            <span
              class={`font-montserrat ${
                tab === "feed" && "text-light-black"
              } text-sm font-semibold`}
            >
              News Feed
            </span>
            {tab === "feed" && (
              <div class="h-1 rounded-t-lg bg-black w-[90%] absolute bottom-0"></div>
            )}
          </button>
          <button
            class="flex flex-col justify-center items-center h-full relative"
            onClick={() => setTab("source")}
          >
            <span
              class={`font-montserrat ${
                tab === "feed" && "text-light-black"
              } text-sm font-semibold`}
            >
              News Feed Source
            </span>
            {tab === "source" && (
              <div class="h-1 rounded-t-lg bg-black w-[90%] absolute bottom-0"></div>
            )}
          </button>
        </div>  */}
        <div class="container mx-auto px-4 flex justify-between items-center">
          <div class="text-left py-4 sm:px-6">
            <span className="font-montserrat text-[14px]/[17.07px] text-[#2E2E2E] text-nowrap">
              Cyber news feed
            </span>
          </div>

          <div class="flex space-x-4 px-8">
            {/* <button
              className="flex flex-col justify-center items-center h-full relative"
              onClick={() => setTab("feed")}
            >
              <span
                className={`font-montserrat ${
                  tab === "feed" && "text-[#2e2e2e] "
                } text-[14px]/[17.07px] text-[#BABABA]`}
              >
                All
              </span>
              {tab === "feed" && (
                <div className="h-1 rounded-t-lg bg-[#FF24BD] w-[100%] absolute top-5"></div>
              )}
            </button> */}
            <button
              className="flex flex-col justify-center items-center h-full relative"
              onClick={() => setTab("profile")}
            >
              {/* <span
                className={`font-montserrat ${
                  tab === "profile" && "text-[#2e2e2e]"
                } text-[14px]/[17.07px] text-[#bababa]`}
              >
                Latest
              </span> */}
              {tab === "profile" && (
                <div className="h-1 rounded-t-lg bg-[#FF24BD] w-[100%] absolute top-5"></div>
              )}
            </button>
            <button
              className="flex flex-col justify-center items-center h-full relative"
              onClick={() => setTab("settings")}
            >
              {/* <span
                className={`font-montserrat ${
                  tab === "settings" && "text-[#2e2e2e]"
                } text-[#bababa] text-[14px]/[17.07px]`}
              >
                Trending
              </span> */}
              {tab === "settings" && (
                <div className="h-1 rounded-t-lg bg-[#FF24BD] w-[100%] absolute top-5"></div>
              )}
            </button>
          </div>
        </div>

        {tab === "feed" && <NewsFeed />}
        {tab === "source" && <NewsSource />}
      </div>
    </BVCard>
  );
};

export default Feeds;
