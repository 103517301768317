import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { login } from "../../endpoints/auth.service";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [success, setSucess] = useState(false);
  const [sentResetpw, setSentResetpw] = useState(false);
  const path = location.pathname;

  const performLogin = (e) => {
    e.preventDefault();
    setErrors([]);
    setSucess(false);
    const newErrors = [];
    if (!email) {
      newErrors.push("email is required");
    }
    if (!password) {
      newErrors.push("password is required");
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    setDisabled(true);
    setSucess(false);
    if (email === "admin@gmail.com" && password === "password") {
      var data = {
        token: "token",
        user: {
          role: "admin",
        },
      };
      setSucess(true);
    } else {
      var data = {
        token: "",
        user: {
          role: "",
        },
      };
      setErrors(["Unauthorize User!"]);
      alert("Unauthorize User!");
      window.location.href = "/login";
    }
    console.log(data.token);
    if (data.token) {
      localStorage.setItem("token", data.token);
      const role = data.user.role;
      if (path === "/admin/login" || path === "/admin/login/") {
        if (role === "admin") {
          setSucess(true);
          // window.location.href = "/dashboard/users";
          setTimeout(() => {
            window.location.href = "/admin";
            // navigate('/')
            // window.location.reload();
          }, 1500);
        } else {
          setErrors(["Unauthorize User!"]);
          setEmail("");
          setPassword("");
        }
      }
    }
    // login(email, password)
    //     .then((data) => {
    //         if (data.token) {
    //             localStorage.setItem('token', data.token)
    //             const role = data.user.role;
    //             if (path === '/login' || path === '/login/') {
    //                 if (role === 'admin') {
    //                     setSucess(true);
    //                     // window.location.href = "/dashboard/users";
    //                     setTimeout(() => {
    //                         window.location.href = '/';
    //                         // navigate('/')
    //                         // window.location.reload();
    //                     }, 1500);
    //                 } else {
    //                     setErrors(["Unauthorize User!"]);
    //                 }
    //             }
    //         }
    //     })
    //     .catch(err => {
    //         setErrors([err]);
    //     })
    //     .finally(() => {
    //         setDisabled(false);
    //     })
  };

  return (
    <>
      <div class="sm:container mx-auto font-sourse-san-pro">
        <div class="mx-auto flex items-center h-screen ">
          <div
            className="bg-gray-100 w-full flex justify-center rounded-lg items-center md:flex-row"
            style={{
              height: "auto",
              minHeight: "500px",
              background: "#fafafa",
            }}
          >
            <div className="w-full pt-6 md:pt-0 md:w-1/2 h-auto md:h-full flex justify-center flex-col">
              <div className="w-full h-auto flex flex-col md:mb-16 ">
                <span className="text-3xl lg:text-5xl font-bold text-center md:text-right ">
                  Welcome to <span className="text-bvblue">KNOWTHREATS</span>
                </span>
                <span className="text-3xl lg:text-5xl font-bold text-center md:text-right mt-2 mb-4 md:mb-0">
                  Cyber Training...&nbsp; &nbsp; &nbsp; &nbsp;
                </span>
              </div>
            </div>
            <div className="w-full md:w-1/2 h-auto md:h-full flex items-center justify-center ">
              <div
                style={{
                  width: "100%",
                  maxWidth: "360px",
                  height: "338px",
                  borderColor: "#ebebeb",
                }}
                className=" relative px-10 bg-white border border-gray-200 rounded-md py-8 mb-16 flex flex-col items-center justify-center"
              >
                <span className="font-bold text-2xl">Log in{user?.token}</span>
                {errors.map((error, index) => {
                  return (
                    <div className="alert w-full alert-danger mt-2" key={index}>
                      {error}
                    </div>
                  );
                })}
                {success && (
                  <div className="alert alert-success mt-2">
                    successfully loggedin
                  </div>
                )}
                {sentResetpw && (
                  <div className="alert alert-success mt-2">
                    We have sent an email link to reset your password
                  </div>
                )}
                <form onSubmit={performLogin} className="w-full flex flex-col">
                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    style={{ marginTop: "38px" }}
                    className="text-sm w-full py-1 border-b border-gray-700 focus:outline-none focus:border-blue-500"
                    type="email"
                    placeholder="Email Address"
                  />

                  <input
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="text-sm mt-4 w-full py-1 border-b border-gray-700 focus:outline-none focus:border-blue-500"
                    type="password"
                    placeholder="Password"
                  />

                  <button
                    disabled={disabled}
                    onClick={performLogin}
                    className="self-center focus:outline-none hover:bg-blue-600 mt-8 mb-8 rounded-md bg-bvbtnblue text-white h-9 flex flex-col items-center justify-center w-24"
                  >
                    Log in
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
