// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BV card */
@media only screen and (min-width: 900px) {
	.bvCardContainer {
		max-width: 1170px;
	}
}

@media only screen and (min-width: 1600px) {
	.bvCardContainer {
		max-width: 1350px;
	}
}

#nav-profile-container #nav-profile-drop {
	display: none;
}

#nav-profile-container:hover #nav-profile-drop {
	display: block;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;CACC;EACC,iBAAiB;CAClB;AACD;;AAEA;CACC;EACC,iBAAiB;CAClB;AACD;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,cAAc;AACf","sourcesContent":["/* BV card */\n@media only screen and (min-width: 900px) {\n\t.bvCardContainer {\n\t\tmax-width: 1170px;\n\t}\n}\n\n@media only screen and (min-width: 1600px) {\n\t.bvCardContainer {\n\t\tmax-width: 1350px;\n\t}\n}\n\n#nav-profile-container #nav-profile-drop {\n\tdisplay: none;\n}\n\n#nav-profile-container:hover #nav-profile-drop {\n\tdisplay: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
