import React from "react";

const ClockIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M10.4993 2.16675C9.405 2.16675 8.32137 2.3823 7.31032 2.80109C6.29927 3.21987 5.38061 3.8337 4.60679 4.60752C3.04399 6.17033 2.16602 8.28994 2.16602 10.5001C2.16602 12.7102 3.04399 14.8298 4.60679 16.3926C5.38061 17.1665 6.29927 17.7803 7.31032 18.1991C8.32137 18.6179 9.405 18.8334 10.4993 18.8334C12.7095 18.8334 14.8291 17.9554 16.3919 16.3926C17.9547 14.8298 18.8327 12.7102 18.8327 10.5001C18.8327 9.40573 18.6171 8.3221 18.1983 7.31105C17.7796 6.30001 17.1657 5.38135 16.3919 4.60752C15.6181 3.8337 14.6994 3.21987 13.6884 2.80109C12.6773 2.3823 11.5937 2.16675 10.4993 2.16675ZM13.9993 14.0001L9.66601 11.3334V6.33341H10.916V10.6667L14.666 12.9167L13.9993 14.0001Z" fill={fill} />
    </svg>

}
export default ClockIcon
