import React from "react";
import AddLogoIcon from "../../assets/svgIcons/AddLogoIcon";
import "../../assets/style/style.css";
const AdminHeader = () => {
  const onClickLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
    window.location.reload();
  };

  return (
    <div
      id="top-navbar"
      className="fixed bg-white w-full shadow-md z-[9999]"
      style={{ top: 0 }}
    >
      <div className="flex w-full h-16 px-12 items-center justify-between self-center">
        <span className="font-light text-gray text-lg font-montserrat tracking-[0.4rem] responsive-padding-logo">
          <AddLogoIcon className={"w-3 h-3"} fill="#6f6f6f" />
          {/* <span className={"w-3 h-3"}>KNOWTHREATS</span> */}
        </span>
        <span className="text-sm text-white">Admin Panel</span>
        <div className="relative h-full py-2" id="nav-profile-container">
          <div className="flex items-center">
            <span className="font-bold text-xs mr-2 text-white">
              Harry Kane
            </span>
            <img
              src={require("../../assets/images/avatar.png")}
              alt="me"
              width="50"
              height="50"
            />
          </div>
          <div
            id="nav-profile-drop"
            className="absolute table right-0 top-16 bg-white shadow-md border min-w-[10rem]"
          >
            <a href="#" className="block px-8 py-2 hover:bg-slate-100 w-full">
              Profile
            </a>
            <div className="block w-full bg-gray-400 h-px"></div>
            <a
              role="button"
              className="block px-8 py-2 hover:bg-slate-100 w-full"
              onClick={onClickLogout}
            >
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
