import '../../assets/style/style.css';
const ContentWrapper = ({ children, className }) => {
	return (
		<div className={`w-full px-40 md:py-8 min-h-screen bg-gray-50 content-padding ${className ?? ''}`} >
			<div className="bvCardContainer mx-auto">
				{children}
			</div>
		</div>
	)
}

export default ContentWrapper;