import { useEffect, useState } from "react";

const TagIcon = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class={_class}>
            <path d="M17.3765 21.8343C17.2172 21.8765 17.0531 21.8999 16.8797 21.8999H2.22186C0.951544 21.8999 0.0468566 20.6624 0.435919 19.4531L1.97811 14.6343C2.47498 13.0874 4.16717 12.2859 5.67654 12.8718C6.67967 13.2609 7.96873 13.5656 9.54842 13.5656C10.4812 13.5656 11.3109 13.4577 12.0469 13.289V15.7265C12.0469 16.2281 12.2437 16.6968 12.5953 17.0531L17.3765 21.8343Z" fill="#4C4C4C" />
            <path d="M9.55315 11.7652C12.2222 11.7652 14.386 9.60151 14.386 6.93242C14.386 4.26333 12.2222 2.09961 9.55315 2.09961C6.88406 2.09961 4.72034 4.26333 4.72034 6.93242C4.72034 9.60151 6.88406 11.7652 9.55315 11.7652Z" fill="#4C4C4C" />
            <path d="M13.8844 11.507L13.2609 12.1305C13.0828 12.3086 12.9844 12.543 12.9844 12.7914V15.7258C12.9844 15.9742 13.0828 16.2133 13.2609 16.3867L18.4922 21.618C18.8578 21.9836 19.4531 21.9836 19.8187 21.618L23.3766 18.0602C23.7422 17.6945 23.7422 17.0992 23.3766 16.7336L18.1453 11.507C17.9672 11.3289 17.7328 11.2305 17.4844 11.2305H14.55C14.3016 11.2305 14.0625 11.3289 13.8844 11.507ZM16.7531 14.9945C16.3875 15.3602 15.7969 15.3602 15.4313 14.9945C15.0656 14.6289 15.0656 14.0383 15.4313 13.6727C15.7969 13.307 16.3875 13.307 16.7531 13.6727C17.1187 14.0383 17.1187 14.6289 16.7531 14.9945Z" fill="#4C4C4C" />
        </svg>
    )
};

export default TagIcon;